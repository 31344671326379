import React from 'react'
import opera from '../images/integrations/opera.svg'
import guestline from '../images/integrations/guestline.svg'
import rms from '../images/integrations/rms.svg'
import giftpro from '../images/integrations/giftpro.svg'
import forsight from '../images/integrations/for-sight.svg'
import mews from '../images/integrations/mews.svg'
import tevalis from '../images/integrations/tevalis.png'
import gantner from '../images/integrations/gantner.png'
import hotsoft from '../images/integrations/hotsoft.svg'
import protel from '../images/integrations/protel.svg'
import allora from '../images/integrations/avvio.svg'
import revinate from '../images/integrations/revinate.svg'
import profitroom from '../images/integrations/profitroom.svg'
import resdiary from '../images/integrations/resdiary.svg'

const trustedBy = [
  {
    id: 'mews',
    alt: 'Mews',
    src: mews,
  },
  {
    id: 'giftpro',
    alt: 'Giftpro',
    src: giftpro,
    className: 'h-32',
  },
  {
    id: 'guestline',
    alt: 'Guestline',
    src: guestline,
  },
  {
    id: 'opera',
    alt: 'Opera',
    src: opera,
  },
  {
    id: 'rms',
    alt: 'RMS',
    src: rms,
  },
  {
    id: 'for-sight',
    alt: 'For-Sight',
    src: forsight,
  },
  {
    id: 'tevalis',
    alt: 'Tevalis',
    src: tevalis,
  },
  {
    id: 'gantner',
    alt: 'Gantner',
    src: gantner,
  },
  {
    id: 'hotsoft',
    alt: 'Hotsoft',
    src: hotsoft,
  },
  {
    id: 'protel',
    alt: 'Protel',
    src: protel,
  },
  {
    id: 'allora',
    alt: 'Allora',
    src: allora,
  },
  {
    id: 'revinate',
    alt: 'Revinate',
    src: revinate,
  },
  {
    id: 'profitroom',
    alt: 'Profitroom',
    src: profitroom,
  },
  {
    id: 'resdiary',
    alt: 'ResDiary',
    src: resdiary,
  },
]

export const PartnerLogoCloud = () => (
  <div className="grid grid-cols-3 md:grid-cols-5 gap-6">
    {trustedBy.map(({ id, alt, src, className = '' }, index) => (
      <div
        key={id}
        className="bg-white bg-opacity-10 rounded-md aspect-square flex items-center justify-center p-4"
        data-sal="slide-up"
        data-sal-duration="500"
        data-sal-delay={index * 100}
        data-sal-easing="ease"
      >
        <img src={src} alt={alt} />
      </div>
    ))}
  </div>
)
