import React from 'react'
import { IconBackground } from '../components/IconBackground'
import Layout from '../components/mdxLayout'
import { PartnerLogoCloud } from '../components/PartnerLogoCloud'
import { PartnerLogoComingSoon } from '../components/PartnerLogoComingSoon'

export default () => (
  <Layout>
    <div className="bg-grape">
      <div className="relative overflow-hidden">
        <div className="text-white opacity-10">
          <IconBackground/>
        </div>

        <div className="relative pt-6 pb-16 sm:pb-18">
          <div className="mx-auto max-w-7xl px-4 sm:mt-8 sm:px-6">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                Partners
              </h1>
              <div className="mt-3 max-w-md mx-auto text-base text-white opacity-50 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                <p className="my-6 text-3xl">Trybe has integrations with many of the top software providers and platforms.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-grape">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <PartnerLogoCloud/>
        <h2
          data-sal="fade"
          data-sal-duration="500"
          data-sal-delay={500}
          className="text-center text-2xl font-medium py-8 text-white"
        >
          and coming soon...
        </h2>
        <PartnerLogoComingSoon/>
      </div>
    </div>
  </Layout>
)
